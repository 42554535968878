import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Nav from "../components/general/Nav";
import Hero from "../components/portfolio/Hero";
import Info from "../components/portfolio/Info";
import CurrentCompanies from "../components/portfolio/CurrentCompanies";
import PrevCompanies from "../components/portfolio/PrevCompanies";
import ToTeam from "../components/portfolio/ToTeam";
import CurrentComp from "../components/portfolio/CurrentComp";
import DemoCountdown from "../components/countdown/DemoCountdown";

function Portfolio() {
  return (
    <CursorContextProvider>
      <Cursor />
      <DemoCountdown />
      <Hero />
      <Info />
      {/* <CurrentCompanies /> */}
      <CurrentComp />
      {/* <PrevCompanies /> */}
      <ToTeam />
      <Nav color={0} home={1} />
    </CursorContextProvider>
  );
}

export default Portfolio;
