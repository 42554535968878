import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import Nav from "../components/general/Nav";
import Hero from "../components/supportus/Hero";
import Intro from "../components/supportus/Intro";
import SupportUs from "../components/supportus/SupportUs";
import styled from "styled-components";
import { colors } from "../assets/colors";
import Countdown from "react-countdown";
import logo from "../assets/logo.png";
import DemoCountdown from "../components/countdown/DemoCountdown";

const Container = styled.div`
  @media only screen and (max-width: 768px) {
  }
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  margin-bottom: 1vw;
`;

const RSVP = styled.button`
  width: 5vh;
  border-radius: 18px;
  color: ${colors.white};
  background-color: ${colors.red};
`;

function Support() {
  return (
    <CursorContextProvider>
      <Cursor />
      <DemoCountdown />
      <Hero />
      <Container>
        <Intro />
        <SupportUs />
      </Container>
      <Nav color={0} home={1} />
    </CursorContextProvider>
  );
}

export default Support;
